import React from "react";
import phoneIcon from "../../assets/pngs/phoneIcon.png";
import whatsappIcon from "../../assets/pngs/whatsappIcon.png";
import "../../components/ui/Quote.css";
const Footer = () => {
  return (
    <footer
      className="div"
      style={{
        position: "unset",
        bottom: 0,
        width: "100%",
        marginTop: "24px",
      }}
    >
      <div className="packages-screen">
        <div className="frame-wrapper">
          <div className="frame-10">
            <a href="tel:+972542182880">
              {" "}
              {/* Replace +123456789 with your actual phone number */}
              <img
                className="communication"
                alt="Communication"
                src={phoneIcon}
              />
            </a>
            <a href="https://wa.me/+972542182880">
              {" "}
              {/* Replace 123456789 with your actual WhatsApp number */}
              <img
                className="communication"
                alt="Communication"
                src={whatsappIcon}
              />
            </a>
            <p className="p">
              <span className="span">עלתה שאלה? רוצה להתייעץ? </span>
              <span className="text-wrapper-3">אני כאן בשמחה</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
