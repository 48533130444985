import MainNavigation from "./MainNavigation";
import classes from "./Layout.module.css";
import Footer from "./Footer";
import React from "react";

function Layout(props) {
  return (
    <>
      <div style={{ minHeight: " 100vh", position: "relative" }}>
        <MainNavigation admin={props.admin} />
        <main className={classes.main}>{props.children}</main>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
