import { createRoot } from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext"; // Import your AuthProvider
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "./App.css";
import Layout from "./components/layout/Layout";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <AuthProvider>
    <Router>
      <Layout>
        <ToastContainer position="bottom-right" autoClose={3000} />
        <App />
      </Layout>
    </Router>
  </AuthProvider>,
);
