// Import necessary modules and components
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MainNavigation.module.css";

import { useAuth } from "../../contexts/AuthContext";
import hazakmanWhiteLogo1 from "../../assets/pngs/hazakman-white-logo-1.png";
import hazakmanWhiteLogo2 from "../../assets/pngs/hazakman-white-logo-2.png";
import phoneIcon from "../../assets/pngs/phoneIcon.png";
import whatsappIcon from "../../assets/pngs/whatsappIcon.png"; // Adjust the path based on your project structure

// MainNavigation component definition
function MainNavigation(props) {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // State management with useState: Initialize the 'isAuthorized' state variable as 'false'
  const { isAuthorized, setIsAuthorized } = useAuth(); // Access isAuthorized state

  // useEffect hook: Fetch and update the authorization status
  useEffect(() => {
    // Make an asynchronous HTTP request to check the user's authorization status
    fetch(process.env.REACT_APP_SERVER_BASE_URL + "/checkAuthAdmin/", {
      credentials: "include", // or 'same-origin' or 'omit'
    })
      .then((response) => {
        if (response.status === 200) {
          // If the response status is 200, set 'isAuthorized' to 'true'
          setIsAuthorized(true);
        } else {
          // If the response status is not 200, set 'isAuthorized' to 'false' and return null
          setIsAuthorized(false);
          return null;
        }
      })
      .catch((error) => {
        // Catch and log any errors that occur during the fetch operation
        console.error("Error checking authorization:", error);
      });
  }, [isAuthorized]); // The effect runs when 'isAuthorized' changes

  const handleLogout = () => {
    console.log("Logging out...");
    // Clear the authentication token stored in the cookie
    fetch(process.env.REACT_APP_SERVER_BASE_URL + "/logout/", {
      credentials: "include", // or 'same-origin' or 'omit'
    })
      .then((response) => {
        if (response.status === 200) {
          // Redirect to the login page or perform other actions
          setIsAuthorized(false);
          console.log("Cookie cleared.");
          navigate(`/admin/login`, { replace: true });
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  // Return the JSX to render the component
  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-light"
        style={{ background: "#333", padding: 0, marginBottom: "30px" }}
      >
        <div
          className="container-fluid justify-content-center"
          style={{ background: "#333", height: "64px" }}
        >
          <a className="navbar-brand" style={{ margin: 0 }}>
            <div className="container">
              <img
                alt="Hazakman white logo"
                src={hazakmanWhiteLogo1}
                style={{ width: "74px", height: "38px", margin: 0 }}
              />
              <img
                alt="Hazakman white logo"
                src={hazakmanWhiteLogo2}
                style={{ width: "197px", height: "29px", margin: 0 }}
              />
            </div>{" "}
          </a>
          {isAuthorized && (
            <>
              {" "}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{
                  fontSize: "0.5rem",
                  padding: "0.2rem 0.4rem",
                  backgroundColor: "#ffffff",
                }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse flex-grow-0"
                id="navbarNav"
              >
                <ul
                  className="navbar-nav text-center"
                  style={{ backgroundColor: "#333", padding: 0, width: "100%" }}
                >
                  <li className="nav-item">
                    <a className="nav-link" aria-current="page">
                      <Link
                        to="/hazalman/createQuote"
                        style={{
                          fontFamily: "Tel Aviv-ModernistRegular",
                          color: "#ffffff",
                          textDecoration: "none",
                        }}
                      >
                        צור הצעה חדשה
                      </Link>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      <Link
                        to="/hazalman/allQuotes"
                        style={{
                          fontFamily: "Tel Aviv-ModernistRegular",
                          color: "#ffffff",
                          textDecoration: "none",
                        }}
                      >
                        כל ההצעות
                      </Link>{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" aria-current="page">
                      <Link
                        to="/hazalman/contractConditionsSource"
                        style={{
                          fontFamily: "Tel Aviv-ModernistRegular",
                          color: "#ffffff",
                          textDecoration: "none",
                        }}
                      >
                        עריכת סעיפי חוזה
                      </Link>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      <Link
                        to={"#"}
                        style={{
                          fontFamily: "Tel Aviv-ModernistRegular",
                          color: "#ffffff",
                          textDecoration: "none",
                        }}
                        onClick={handleLogout}
                      >
                        להתנתק
                      </Link>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
}

export default MainNavigation; // Export the MainNavigation component
