// App.js
import React, { lazy, Suspense } from "react";
import "./fonts.css";
import styles from "./index.css";
import { Routes, Route } from "react-router-dom";
const QuoteLogin = lazy(() => import("./pages/QuoteLogin"));
const AdminLogin = lazy(() => import("./pages/AdminLogin"));
const AllQuotes = lazy(() => import("./pages/AllQuotes"));
const CreateQuote = lazy(() => import("./pages/CreateQuote"));
const EditQuote = lazy(() => import("./pages/EditQuote"));
const QuoteAndContract = lazy(() => import("./pages/QuoteAndContract"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const PaymentMethod = lazy(() => import("./pages/PaymentMethod"));
const BankTransfer = lazy(() => import("./pages/BankTransfer"));
const SuccessfulPayment = lazy(() => import("./pages/SuccessfulPayment"));
const FailurePage = lazy(() => import("./pages/FailurePage"));
const ContractConditionsSource = lazy(() =>
  import("./pages/ContractConditionsSource"),
);
const ContractForPrint = lazy(() => import("./pages/ContractForPrint"));
const Contract = lazy(() => import("./components/ui/Contract"));

function App() {
  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        }
      >
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route
            path="/hazalman/login/:quoteId"
            element={<QuoteLogin company={"hazalman"} />}
          />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route
            path="/hazalman/allQuotes"
            element={<AllQuotes company={"hazalman"} />}
          />
          <Route
            path="/hazalman/createQuote"
            element={<CreateQuote edit={false} />}
          />
          <Route
            path="/hazalman/editQuote/:quoteId"
            element={<EditQuote edit={true} />}
          />
          <Route
            path="/hazalman/:quoteId"
            element={<QuoteAndContract company={"hazalman"} />}
          />
          <Route path="/hazalman/contract" element={<Contract />} />
          <Route
            path="/hazalman/PaymentMethod/:quoteId"
            element={<PaymentMethod />}
          />
          <Route
            path="/hazalman/bankTransfer/:quoteId"
            element={<BankTransfer />}
          />

          <Route
            path="/hazalman/successfulPayment/:quoteId"
            element={<SuccessfulPayment />}
          />

          <Route
            path="/hazalman/printQuote/:quoteId"
            element={<ContractForPrint />}
          />

          <Route
            path="/hazalman/failurePage/:message"
            element={<FailurePage />}
          />
          <Route
            path="/hazalman/contractConditionsSource"
            element={<ContractConditionsSource />}
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
